<template>
  <div class="tab-content">
    <div class="pagenamPT">
      <h3 class="pagename2">베팅 현황({{ statTypeStr }})</h3>
    </div>
    <div class="partnerTop">
      <div></div>
      <date-filter-Pt :startDate="startDate"
                      :endDate="endDate"
                      @update="updateDate"
                      @search="onSearch" />
    </div>
    <div class="strTableWrap">
      <PartnerHierarchy @onClickMemId="loadSubList"></PartnerHierarchy>
      <div class="strTablescr">
        <h2 class="betTitle">해당 파트너 베팅 & 롤링데이터</h2>
        <div class="strTablePC mb40">
          <table class="strTablePT">
            <thead>
              <tr>
                <th>구분</th>
                <th>아이디<br>닉네임</th>
                <th>파트너 총충전</th>
                <th>파트너 총환전</th>
                <th>총 베팅금</th>
                <th>총 당첨금</th>
                <th>총베팅-총당첨</th>
                <th>총 롤링금</th>
                <th>베팅손익</th>
                <th>총 루징금</th>
                <th>루징%</th>
                <th>보유 머니</th>
                <th>보유 롤링금</th>
              </tr>
            </thead>
            <tbody>
              <tr v-if="myStatData">
                <td>{{ myStatData.partnerLevelName }}</td>
                <td>{{ myStatData.memId }}<br><em class="nick">{{ myStatData.memNick }}</em></td>
                <td class="text-right">{{ thousand(myStatData.cashInAmtSum || 0) }}</td>
                <td class="text-right">{{ thousand(myStatData.cashOutAmtSum || 0) }}</td>
                <td>{{ thousand(myStatData.betAmtSum || 0) }}</td>
                <td>{{ thousand(myStatData.winAmtSum || 0) }}</td>
                <td>{{ thousand(myStatData.winLoseSum || 0) }}</td>
                <td>{{ thousand(myStatData.rollingSum || 0) }}</td>
                <td>{{ thousand(myStatData.totalBEP || 0) }}</td>
                <td>{{ thousand(myStatData.losingAmtSum || 0) }}</td>
                <td>{{ thousand(myStatData.loseRate || 0) }}</td>
                <td>{{ thousand(myStatData.cashAmtSum || 0) }}</td>
                <td>{{ thousand(myStatData.pointAmtSum || 0) }}</td>
              </tr>
              <tr v-else>
                <td colspan="11">내역이 없습니다.</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="strTablePC">
          <h2 class="betTitle">하부 파트너 베팅 & 롤링데이터</h2>
          <table class="strTablePT">
            <thead>
              <tr>
                <th>구분</th>
                <th>아이디<br>닉네임</th>
                <th>파트너 총충전</th>
                <th>파트너 총환전</th>
                <th>총 베팅금</th>
                <th>총 당첨금</th>
                <th>총베팅-총당첨</th>
                <th>총 롤링금</th>
                <th>베팅손익</th>
                <th>총 루징금</th>
                <th>루징%</th>
                <th>보유 머니</th>
                <th>보유 롤링금</th>
              </tr>
            </thead>
            <tbody v-if="botStatList && botStatList.length > 0">
              <tr v-for="item in botStatList" :key="item.memId">
                <td>{{ item.partnerLevelName }}</td>
                <td>{{ item.memId }}<br><em class="nick">{{ item.memNick }}</em></td>
                <td class="text-right">{{ thousand(item.cashInAmtSum || 0) }}</td>
                <td class="text-right">{{ thousand(item.cashOutAmtSum || 0) }}</td>
                <td>{{ thousand(item.betAmtSum || 0) }}</td>
                <td>{{ thousand(item.winAmtSum || 0) }}</td>
                <td>{{ thousand(item.winLoseSum || 0) }}</td>
                <td>{{ thousand(item.rollingSum || 0) }}</td>
                <td>{{ thousand(item.totalBEP || 0) }}</td>
                <td>{{ thousand(item.losingAmtSum || 0) }}</td>
                <td>{{ thousand(item.loseRate || 0) }}</td>
                <td>{{ thousand(item.cashAmtSum || 0) }}</td>
                <td>{{ thousand(item.pointAmtSum || 0) }}</td>
              </tr>
            </tbody>
            <tbody v-else>
              <tr><td colspan="11">내역이 없습니다.</td></tr>
            </tbody>
            <tfoot v-if="botStatSum">
              <tr>
                <td>합계</td>
                <td></td>
                <td class="text-right">{{ thousand(botStatSum.cashInAmtSum || 0) }}</td>
                <td class="text-right">{{ thousand(botStatSum.cashOutAmtSum || 0) }}</td>
                <td>{{ thousand(botStatSum.betAmtSum || 0) }}</td>
                <td>{{ thousand(botStatSum.winAmtSum || 0) }}</td>
                <td>{{ thousand(botStatSum.winLoseSum || 0) }}</td>
                <td>{{ thousand(botStatSum.rollingSum || 0) }}</td>
                <td>{{ thousand(botStatSum.totalBEP || 0) }}</td>
                <td>{{ thousand(botStatSum.losingAmtSum || 0) }}</td>
                <td>{{ thousand(botStatSum.loseRate || 0) }}</td>
                <td>{{ thousand(botStatSum.cashAmtSum || 0) }}</td>
                <td>{{ thousand(botStatSum.pointAmtSum || 0) }}</td>
              </tr>
            </tfoot>
          </table>
        </div>
        <div class="strTableM">
          <div class="strTablePTM">
            <h2 class="betTitle2">해당 파트너 베팅 & 롤링데이터</h2>
            <ul v-if="myStatData">
              <li>
                <em>구분</em>
                <div>{{ myStatData.partnerLevelName }}</div>
              </li>
              <li>
                <em>아이디<br>닉네임</em>
                <div>
                  {{myStatData.memId}}<br />
                  <span class="nick">{{myStatData.memNick}}</span>
                </div>
              </li>
              <li>
                <em>파트너 총충전</em>
                <div>{{ thousand(myStatData.cashInAmtSum || 0) }}</div>
              </li>
              <li>
                <em>파트너 총환전</em>
                <div>{{ thousand(myStatData.cashOutAmtSum || 0) }}</div>
              </li>
              <li>
                <em>총 베팅금</em>
                <div>{{ thousand(myStatData.betAmtSum || 0) }}</div>
              </li>
              <li>
                <em>총 당첨금</em>
                <div>{{ thousand(myStatData.winAmtSum || 0) }}</div>
              </li>
              <li>
                <em>총베팅-총당첨</em>
                <div>{{ thousand(myStatData.winLoseSum || 0) }}</div>
              </li>
              <li>
                <em>총 롤링금</em>
                <div>{{ thousand(myStatData.rollingSum || 0) }}</div>
              </li>
              <li>
                <em>베팅손익</em>
                <div>{{ thousand(myStatData.totalBEP || 0) }}</div>
              </li>
              <li>
                <em>총 루징금</em>
                <div></div>
              </li>
              <li>
                <em>루징%</em>
                <div></div>
              </li>
              <li>
                <em>보유 머니</em>
                <div>{{ thousand(myStatData.cashAmtSum || 0) }}</div>
              </li>
              <li>
                <em>보유 롤링금</em>
                <div>{{ thousand(myStatData.pointAmtSum || 0) }}</div>
              </li>
            </ul>
          </div>
        </div>
        <div class="strTableM">
          <div class="strTablePTM">
            <template v-if="botStatList && botStatList.length > 0">
              <h2 class="betTitle2">하부 파트너 베팅 & 롤링데이터</h2>
              <ul v-for="item in botStatList" :key="item.memId">
                <li>
                  <em>구분</em>
                  <div>{{ item.partnerLevelName }}</div>
                </li>
                <li>
                  <em>아이디<br>닉네임</em>
                  <div>
                    {{ item.memId }}<br>
                    <span class="nick">{{item.memNick}}</span>
                  </div>
                </li>
                <li>
                  <em>파트너 총충전</em>
                  <div>{{ thousand(item.cashInAmtSum || 0) }}</div>
                </li>
                <li>
                  <em>파트너 총환전</em>
                  <div>{{ thousand(item.cashOutAmtSum || 0) }}</div>
                </li>
                <li>
                  <em>총 베팅금</em>
                  <div>{{ thousand(item.betAmtSum || 0) }}</div>
                </li>
                <li>
                  <em>총 당첨금</em>
                  <div>{{ thousand(item.winAmtSum || 0) }}</div>
                </li>
                <li>
                  <em>총베팅-총당첨</em>
                  <div>{{ thousand(item.winLoseSum || 0) }}</div>
                </li>
                <li>
                  <em>총 롤링금</em>
                  <div>{{ thousand(item.rollingSum || 0) }}</div>
                </li>
                <li>
                  <em>베팅손익</em>
                  <div>{{ thousand(item.totalBEP || 0) }}</div>
                </li>
                <li>
                  <em>총 루징금</em>
                  <div></div>
                </li>
                <li>
                  <em>루징%</em>
                  <div></div>
                </li>
                <li>
                  <em>보유 머니</em>
                  <div>{{ thousand(item.cashAmtSum || 0) }}</div>
                </li>
                <li>
                  <em>보유 롤링금</em>
                  <div>{{ thousand(item.pointAmtSum || 0) }}</div>
                </li>
              </ul>
            </template>
            <h2 class="betTitle2">합계</h2>
            <ul v-if="botStatSum">
              <li>
                <em>파트너 총충전</em>
                <div>{{ thousand(botStatSum.cashInAmtSum || 0) }}</div>
              </li>
              <li>
                <em>파트너 총환전</em>
                <div>{{ thousand(botStatSum.cashOutAmtSum || 0) }}</div>
              </li>
              <li>
                <em>총 베팅금</em>
                <div>{{ thousand(botStatSum.betAmtSum || 0) }}</div>
              </li>
              <li>
                <em>총 당첨금</em>
                <div>{{ thousand(botStatSum.winAmtSum || 0) }}</div>
              </li>
              <li>
                <em>총베팅-총당첨</em>
                <div>{{ thousand(botStatSum.winLoseSum || 0) }}</div>
              </li>
              <li>
                <em>총 롤링금</em>
                <div>{{ thousand(botStatSum.rollingSum || 0) }}</div>
              </li>
              <li>
                <em>베팅손익</em>
                <div>{{ thousand(botStatSum.totalBEP || 0) }}</div>
              </li>
              <li>
                <em>총 루징금</em>
                <div></div>
              </li>
              <li>
                <em>루징%</em>
                <div></div>
              </li>
              <li>
                <em>보유 머니</em>
                <div>{{ thousand(botStatSum.cashAmtSum || 0) }}</div>
              </li>
              <li>
                <em>보유 롤링금</em>
                <div>{{ thousand(botStatSum.pointAmtSum || 0) }}</div>
              </li>
              <!-- <li>
                <em>합계</em>
                <div class="betTotal">
                  <span>{{ thousand(botStatSum.cashInAmtSum || 0) }}</span>
                  <span>{{ thousand(botStatSum.cashOutAmtSum || 0) }}</span>
                  <span>{{ thousand(botStatSum.betAmtSum || 0) }}</span>
                  <span>{{ thousand(botStatSum.winAmtSum || 0) }}</span>
                  <span>{{ thousand(botStatSum.winLoseSum || 0) }}</span>
                  <span>{{ thousand(botStatSum.rollingSum || 0) }}</span>
                  <span>{{ thousand(botStatSum.totalBEP || 0) }}</span>
                  <span></span>
                  <span></span>
                  <span>{{ thousand(botStatSum.cashAmtSum || 0) }}</span>
                  <span>{{ thousand(botStatSum.pointAmtSum || 0) }}</span>
                </div>
              </li> -->
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import DateFilterPt from '@/components/ui/DateFilterPt'
import PartnerHierarchy from '@/components/common/mobile/PartnerHierarchy.vue'
import { getDateStr, thousand } from '@/libs/utils'
import { addDays } from 'date-fns'
import { getBettingListV2 } from '@/api/v2/betting'
// import { getBettingListV2, getBettingDetail } from '@/api/v2/betting'
import { mapState } from 'vuex'
export default {
  name: 'betAll',
  components: { PartnerHierarchy, DateFilterPt },
  computed: {
    ...mapState([
      'userData',
      'gameCount',
      'commonCodeByOrder',
      'commonCodeByCode'
    ]),
    statTypeStr () {
      if (this.statType === 'total') {
        return '전체'
      } else if (this.statType === 'casino') {
        return '카지노'
      } else if (this.statType === 'slot') {
        return '슬롯'
      }

      return ''
    }
  },
  data () {
    return {
      reqData: {
        memId: ''
      },
      statType: 'total',
      startDate: getDateStr(addDays(new Date(), 0), 'yyyy-MM-dd 00:00:00'),
      endDate: getDateStr(addDays(new Date(), 0), 'yyyy-MM-dd 23:59:59'),
      myStatData: null,
      botStatList: null,
      botStatSum: null,
      selectMemId: null
    }
  },
  created () {
    this.emitter.emit('Loading', true)
    console.log(this.$route.name)
    const name = this.$route.name
    this.statType = name.replace('betting', '')

    this.loadSubList(this.userData.memId, 'main', 1)
    this.emitter.emit('Loading', false)
  },
  methods: {
    thousand,
    updateDate (value) {
      this.startDate = value.startDate
      this.endDate = value.endDate
    },
    onSearch () {
      this.loadSubList(this.selectMemId)
    },
    loadSubList (memId, type, page) {
      this.emitter.emit('Loading', true)
      if (!memId) {
        memId = this.selectMemId
      } else {
        this.selectMemId = memId
      }
      if (!page) {
        page = this.pageInfo.page
      } else {
        this.pageInfo.page = page
      }

      const params = {
        memId,
        statType: this.statType,
        startDate: this.startDate,
        endDate: this.endDate
      }

      getBettingListV2(params).then(res => {
        console.log('getBettingListV2 : ', params, res)
        this.myStatData = null
        this.botStatList = null
        this.botStatSum = null
        const result = res.data
        if (result.resultCode === '0') {
          this.myStatData = result.data.myStatData
          this.botStatList = result.data.botStatList
          this.botStatSum = result.data.botStatSum
        }

        this.emitter.emit('Loading', false)
      })
    }
  }
}
</script>

<style scoped>
.flex-c {
  flex-direction: column;
}
.gap-2 {
  gap: 2px;
}
@media (max-width: 1000px) {
  .strTablescr {width: 100%;}
  .strTablePTM ul {padding: 0;}
}
.subPT_1 li {
  position: relative;
}
.betTitle {font-weight: bold;margin: 20px 0 20px 20px;font-size: 20px;}
.betTitle2 {font-weight: bold;margin: 10px 0 10px 20px;font-size: 12px;text-align: left;}

.betTotal {display: flex;align-items: center;gap: 10px;}

</style>
